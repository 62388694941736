import { ChipVariant } from "@ag/design-system/atoms";

import { ContractType } from "../entities/inventory";

export const getContractTypeChip = (
  type: ContractType,
): { variant: ChipVariant; label: string } => ({
  variant: {
    [ContractType.Buyout]: "danger" as ChipVariant,
    [ContractType.Resale]: "warning" as ChipVariant,
  }[type],
  label: {
    [ContractType.Buyout]: "Buyout",
    [ContractType.Resale]: "Resale",
  }[type],
});
