import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";

import {
  ActionsCell,
  ActionsCellValue,
  DateCell,
  LinkCell,
  LinkCellValue,
  TextCell,
  TextCellValue,
} from "@ag/design-system/organisms";

import {
  ACTIONS_CELL_WIDTH,
  TableSortingState,
  useTable,
} from "~components/table";

import { Client } from "../entities/client";

type TableData = {
  id: string;
  adminId?: LinkCellValue | TextCellValue;
  name: string;
  createdAt: string;
  actions: ActionsCellValue;
};

export const useClientsTable = (
  data: Client[] | undefined,
  state: TableSortingState,
  onClientDelete: (args: { id: string; name: string }) => void,
  hasAdminAccess?: boolean,
  hasDeletePermission?: boolean,
) => {
  const rowData = useMemo(
    () => getRowData(data, onClientDelete, hasAdminAccess, hasDeletePermission),
    [data, hasAdminAccess, hasDeletePermission, onClientDelete],
  );

  return useTable<TableData>({
    columns: getColumns(),
    data: rowData,
    state,
    onSortingChange: state.setSorting,
  });

  function getColumns() {
    const columnHelper = createColumnHelper<TableData>();

    return [
      columnHelper.accessor("id", {
        header: "ID",
      }),
      columnHelper.accessor("name", {
        header: "Name",
        enableSorting: true,
      }),
      columnHelper.accessor("adminId", {
        header: "Created By",
        cell: hasAdminAccess ? LinkCell : TextCell,
      }),
      columnHelper.accessor("createdAt", {
        header: "Created At",
        cell: DateCell,
        enableSorting: true,
      }),
      columnHelper.accessor("actions", {
        header: "Actions",
        cell: ActionsCell,
        size: ACTIONS_CELL_WIDTH,
      }),
    ];
  }

  function getRowData(
    data: Client[] | undefined,
    onClientDelete: (args: { id: string; name: string }) => void,
    hasAdminAccess?: boolean,
    hasDeletePermission?: boolean,
  ): TableData[] {
    if (!data) return [];

    return data.map(client => ({
      id: client.id,
      name: client.name,
      adminId: client.adminId
        ? hasAdminAccess
          ? {
              title: client.adminId,
              url: `/admins/${client.adminId}`,
            }
          : client.adminId
        : undefined,
      createdAt: client.createdAt,
      actions: {
        title: "Actions",
        items: [
          {
            children: "Delete",
            isDanger: true,
            isDisabled: !hasDeletePermission,
            onClick: () => onClientDelete({ id: client.id, name: client.name }),
          },
        ],
      },
    }));
  }
};
