import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";

import { transformInitialFilterValues } from "@ag/components/Filters";
import { ButtonSelect, ComboBox, Select } from "@ag/design-system/molecules";
import {
  ButtonSelectField,
  ComboBoxField,
  InputField,
  SelectField,
} from "@ag/form-fields";

import { Filters } from "~components/filters";
import { BuyoutContractStatus } from "~features/buyout-agreements";
import { useCarbonCountries } from "~features/countries";
import {
  CertificaGroupStatus,
  InventoryFilters,
  InventoryFiltersSchema,
} from "~features/inventory/entities/inventory";
import { ResaleContractStatus } from "~features/resale-agreements";
import { transformEnumToLabels } from "~helpers";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  query: object;
  onClear: () => void;
  onSubmit: (data: InventoryFilters) => void;
};

const InventoryTableFilters = ({
  isOpen,
  onClose,
  query,
  onClear,
  onSubmit,
}: Props) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<InventoryFilters>({
    values: transformInitialFilterValues(query),
    resolver: zodResolver(InventoryFiltersSchema),
  });

  const { data: countries } = useCarbonCountries();

  const certificatesGroupStatusLabels =
    transformEnumToLabels(CertificaGroupStatus);

  const contractStatuses = new Set([
    ...Object.values(ResaleContractStatus),
    ...Object.values(BuyoutContractStatus),
  ]);
  const resaleContractStatusLabels =
    transformEnumToLabels(ResaleContractStatus);
  const buyoutContractStatusLabels =
    transformEnumToLabels(BuyoutContractStatus);

  const handleFormFiltersClear = () => {
    reset();
    onClear();
  };

  return (
    <Filters.Drawer
      isOpen={isOpen}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      onClear={handleFormFiltersClear}
    >
      <InputField
        {...register("importId")}
        label="Import ID"
        error={errors.importId}
        type="number"
      />

      <InputField
        {...register("fieldId")}
        label="Field ID"
        error={errors.fieldId}
        type="number"
      />

      <Controller
        name="status"
        control={control}
        render={({ field, fieldState }) => (
          <SelectField
            {...field}
            {...fieldState}
            value={field.value ?? ""}
            label="Certificate Group Status"
            optionsClassName="z-modal"
          >
            <Select.OptionAll>All</Select.OptionAll>

            {Object.values(CertificaGroupStatus).map(type => (
              <Select.Option key={type} value={type}>
                {certificatesGroupStatusLabels[type]}
              </Select.Option>
            ))}
          </SelectField>
        )}
      />

      <Controller
        name="eligibleVerra"
        control={control}
        render={({ field, fieldState }) => (
          <ButtonSelectField
            label="Eligible Verra"
            {...field}
            {...fieldState}
            testId="eligible-verra"
          >
            <ButtonSelect.Option value="true">Yes</ButtonSelect.Option>

            <ButtonSelect.Option value="false">No</ButtonSelect.Option>
          </ButtonSelectField>
        )}
      />

      <Controller
        name="eligibleIso"
        control={control}
        render={({ field, fieldState }) => (
          <ButtonSelectField
            label="Eligible Iso"
            {...field}
            {...fieldState}
            testId="eligible-iso"
          >
            <ButtonSelect.Option value="true">Yes</ButtonSelect.Option>

            <ButtonSelect.Option value="false">No</ButtonSelect.Option>
          </ButtonSelectField>
        )}
      />

      <Controller
        name="countryCode"
        control={control}
        render={({ field, fieldState }) => (
          <ComboBoxField
            {...field}
            {...fieldState}
            value={field.value ?? ""}
            label="Country"
            loadingText="Loading..."
            emptyText="No matching results"
            optionsClassName="z-modal"
          >
            {countries?.map(country => (
              <ComboBox.Item key={country.code}>{country.name}</ComboBox.Item>
            ))}
          </ComboBoxField>
        )}
      />

      <InputField
        {...register("vintageYear")}
        label="Vintage Year"
        type="number"
        error={errors.vintageYear}
      />

      <InputField
        {...register("contractId")}
        label="Contract ID"
        type="number"
        error={errors.contractId}
      />

      <Controller
        name="contractType"
        control={control}
        render={({ field, fieldState }) => (
          <SelectField
            {...field}
            {...fieldState}
            value={field.value ?? ""}
            label="Contract Type"
            optionsClassName="z-modal"
          >
            <Select.OptionAll>All</Select.OptionAll>

            <Select.Option key="buyout" value="buyout">
              Buyout
            </Select.Option>

            <Select.Option key="resale" value="resale">
              Resale
            </Select.Option>
          </SelectField>
        )}
      />

      <Controller
        name="contractStatus"
        control={control}
        render={({ field, fieldState }) => (
          <SelectField
            {...field}
            {...fieldState}
            value={field.value ?? ""}
            label="Contract Status"
            optionsClassName="z-modal"
          >
            <Select.OptionAll>All</Select.OptionAll>

            {[...contractStatuses].map(type => (
              <Select.Option key={type} value={type}>
                {resaleContractStatusLabels[type] ||
                  buyoutContractStatusLabels[type]}
              </Select.Option>
            ))}
          </SelectField>
        )}
      />
    </Filters.Drawer>
  );
};

export default InventoryTableFilters;
