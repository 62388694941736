import { Icon } from "@ag/design-system/assets";
import { Confirmation, Modal } from "@ag/design-system/organisms";

type DeleteConfirmationProps = {
  isOpen: boolean;
  isDeleting?: boolean;
  clientName: string;
  onHide: () => void;
  onConfirm: () => void;
};

export const DeleteClientConfirmation = ({
  isOpen,
  clientName,
  isDeleting,
  onHide,
  onConfirm,
}: DeleteConfirmationProps) => (
  <Modal.Root className="p-0" isOpen={isOpen} onRequestClose={onHide}>
    <Modal.Content>
      <Confirmation.Root variant="danger">
        <Icon name="warning" fontSize="46" className="text-red-800" />
        <Confirmation.Title key="title">
          <>
            Do you want to delete client <strong>{clientName}</strong>
            <br />
            and their associated documents?
          </>
        </Confirmation.Title>

        <Confirmation.Description>
          Clients with associated certificates cannot be deleted
        </Confirmation.Description>
        <Confirmation.Actions>
          <Confirmation.Cancel onClick={onHide}>Cancel</Confirmation.Cancel>

          <Confirmation.Confirm
            isLoading={isDeleting}
            onClick={() => onConfirm()}
          >
            Delete
          </Confirmation.Confirm>
        </Confirmation.Actions>
      </Confirmation.Root>
    </Modal.Content>
  </Modal.Root>
);
