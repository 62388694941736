import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useEffectOnceWhen } from "rooks";

import { getSearchParams } from "@ag/utils/helpers";

import FullPageSpinner from "~components/full-page-spinner";
import { addCurrentAdminToSentry } from "~config/sentry";
import { useSessionContext } from "~features/auth";
import { AmplitudeContext } from "~lib/ampli";

import { useSessionRefresh } from "../hooks/use-session-refresh";

type Props = {
  children: React.ReactElement;
};
export const AuthorizedRoute = ({ children }: Props) => {
  const location = useLocation();

  const { currentAdmin, isSignedIn, isLoading } = useSessionContext();
  const { updateUser, isLoaded: isAmplitudeLoaded } =
    useContext(AmplitudeContext);
  const { isSessionRefreshing } = useSessionRefresh();

  useEffectOnceWhen(() => {
    addCurrentAdminToSentry(currentAdmin);
    updateUser(currentAdmin!.email);
  }, isSignedIn);

  if (
    isLoading ||
    (isSessionRefreshing && !currentAdmin) ||
    !isAmplitudeLoaded
  ) {
    return <FullPageSpinner />;
  }

  if (!isSignedIn) {
    if (location.pathname === "/login") return null;

    const unauthorizedRedirectPath =
      "/login" +
      getSearchParams({
        redirect: location.pathname,
      });

    return <Navigate to={unauthorizedRedirectPath} replace />;
  }

  return children;
};
