const envs = [
  "REACT_APP_API_RUBY_BASE_URL",
  "REACT_APP_API_RUBY_ACCESS_TOKEN",
  "REACT_APP_API_NODE_AUTH_BASE_URL",
  "REACT_APP_API_NODE_AUTH_ACCESS_TOKEN",
  "REACT_APP_API_NODE_USER_MGT_BASE_URL",
  "REACT_APP_API_NODE_USER_MGT_ACCESS_TOKEN",
  "REACT_APP_API_NODE_CARBON_BASE_URL",
  "REACT_APP_API_NODE_CARBON_ACCESS_TOKEN",
  "REACT_APP_GOOGLE_MAPS_API_KEY",
  "REACT_APP_AMPLITUDE_API_KEY",
  "REACT_APP_FARMER_URL",
  "REACT_APP_APP_ENVIRONMENT",
  "REACT_APP_SENTRY_DSN",
  "REACT_APP_LEDGER_IMPORT_RESOURCE_ENABLED",
  "REACT_APP_FLATFILE_PUBLISHABLE_KEY",
  "REACT_APP_FLATFILE_ENVIRONMENT_ID",
  "REACT_APP_API_NODE_MARKETS_BASE_URL",
  "REACT_APP_API_NODE_MARKETS_ACCESS_TOKEN",
  "REACT_APP_API_CROPS_FERTILISERS_BASE_URL",
  "REACT_APP_API_CROPS_FERTILISERS_ACCESS_TOKEN",
] as const;

type WindowWithEnvs = {
  [K in (typeof envs)[number]]: string | undefined;
};

declare global {
  interface Window extends WindowWithEnvs {}
}

export const env = envs.reduce(
  (acc, env) => {
    acc[env] = window[env];
    return acc;
  },
  {} as Record<(typeof envs)[number], string | undefined>,
);
