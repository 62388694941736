"use client";

import { Experiment, ExperimentClient } from "@amplitude/experiment-js-client";
import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

import { useEffectOnce } from "@ag/utils/hooks";

type AmplitudeContextValue = {
  isLoaded: boolean;
  experiment: ExperimentClient | null;
  updateUser: (userId: string | undefined) => void;
};

export const AmplitudeContext = createContext<AmplitudeContextValue>({
  isLoaded: false,
  updateUser: () => {},
  experiment: null,
});

type AmplitudeProviderProps = {
  apiKey: string;
  children: ReactNode;
};

export function AmplitudeProvider({
  children,
  apiKey,
}: AmplitudeProviderProps) {
  const [isLoaded, setIsLoaded] = useState(false);

  const experiment = useRef<ExperimentClient | null>(null);

  useEffectOnce(() => {
    (async () => {
      experiment.current = Experiment.initialize(apiKey, {
        automaticExposureTracking: true,
        fetchOnStart: true,
        serverZone: "eu",
      });

      await experiment.current.start();

      setIsLoaded(true);
    })();
  });

  const updateUser = useCallback(async (userId: string | undefined) => {
    if (!experiment.current) return;

    experiment.current.setUser({ user_id: userId });
    await experiment.current.fetch();
  }, []);

  const value = useMemo(
    () => ({
      experiment: experiment.current,
      isLoaded,
      updateUser,
    }),
    [isLoaded, updateUser],
  );

  return (
    <AmplitudeContext.Provider value={value}>
      {children}
    </AmplitudeContext.Provider>
  );
}

/**
 * Check if the given flag is enabled for the current user.
 *
 * NOTE: This assumes that you wait for the flags to load before receiving this value.
 * That is done automatically for authorized routes, but not for pre-login routes.
 * For pre-login cases, you should use `useContext(AmplitudeContext)` to check if amplitude is loaded.
 */
export const useIsFlagActive = (name: string) => {
  const { experiment } = useContext(AmplitudeContext);

  if (!experiment) throw new Error("Experiment not available");

  return experiment.variant(name).value === "on";
};
