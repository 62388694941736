import { useEffect, useState } from "react";

import { Icon } from "@ag/design-system/assets";
import { Button, FileRejection, InfoBox } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import { DropzoneField } from "@ag/form-fields";
import { ToastNotification } from "@ag/utils/services";

import {
  useCreatePresignedUrlMutation,
  useProcessInventoryImportMutation,
  useUploadInventoryFileMutation,
} from "~features/inventory";

import {
  ProgressStatus,
  useProgressPollingQuery,
} from "../api/progress-polling";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const ProcessingLoader = ({
  progress,
  onComplete,
  onError,
}: {
  progress?: ProgressStatus;
  onComplete: () => void;
  onError: () => void;
}) => {
  useEffect(() => {
    if (progress?.percentage === 100) {
      onComplete();
    }
    if (onError && progress?.hasFailed) {
      onError();
    }
  }, [progress, onError, onComplete]);

  return (
    <div>
      <h2 className="text-h2">Processing file...</h2>
      <p className="text-p1">
        The file is being processed. This may take a few minutes.
      </p>

      {progress && (
        <div className="mt-4">
          {progress.total > 0 && (
            <span className="text-p1">
              {(progress.current / 1000000).toFixed(2)}
              {" / "}
              {(progress.total / 1000000).toFixed(2)}
              MB
            </span>
          )}

          {progress.percentage !== null && (
            <span className="float-right text-p1">
              {progress.percentage}% complete
            </span>
          )}

          <div className="bg-gray-200 dark:bg-gray-700 mt-1 h-3 w-full rounded-full bg-grey-200">
            <div
              className="h-3 rounded-full bg-data-purple-700 transition-all duration-300 ease-linear"
              style={{ width: `${progress.percentage || 0}%` }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const InventoryImport = ({ isOpen, onClose }: Props) => {
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    console.warn(e.target);
  };
  const [fileErrors, setFileErrors] = useState<
    { code: string; message: string }[] | undefined
  >();
  const [processingId, setProcessingId] = useState<string>();
  const [file, setFile] = useState<File | undefined>();
  const createPresignedUrl = useCreatePresignedUrlMutation();
  const uploadInventoryFile = useUploadInventoryFileMutation();
  const processInventoryImport = useProcessInventoryImportMutation();
  const progressPolling = useProgressPollingQuery(processingId as string, {
    enabled: Boolean(processingId),
  });

  const handleFileRejection = (fileRejection: FileRejection) => {
    const errors = fileRejection.errors.map(({ code, message }) => {
      switch (code) {
        case "file-too-large":
          return {
            code,
            message:
              "File is too large, please upload a file smaller than 500KB",
          };
        case "file-invalid-type":
          return {
            code,
            message: "Invalid file type, please upload a .csv or .xlsx file",
          };
        default:
          return { code, message };
      }
    });

    setFileErrors(errors);
  };

  const handleFileUploadSuccess = (id: string) => {
    processInventoryImport.mutate(id, {
      onSuccess: () => setProcessingId(id),
    });
  };

  const handleFileAccepted = (acceptedFile: File | undefined) => {
    if (!acceptedFile) ToastNotification.error("No file uploaded");
    else {
      setFile(acceptedFile);
      createPresignedUrl.mutate(
        { filename: acceptedFile.name },
        {
          onSuccess: ({ signedUrl, id }) => {
            uploadInventoryFile.mutate(
              { file: acceptedFile, signedUrl },
              { onSuccess: () => handleFileUploadSuccess(id) },
            );
          },
        },
      );
    }
  };

  const handleFileDropped = (
    acceptedFile: File | undefined,
    fileRejection: FileRejection | undefined,
  ) => {
    setFileErrors(undefined);

    if (fileRejection) {
      handleFileRejection(fileRejection);
    } else {
      handleFileAccepted(acceptedFile);
    }
  };

  const handleProcessingComplete = () => {
    setFile(undefined);
    setFileErrors(undefined);
    setProcessingId(undefined);
    onClose();
    ToastNotification.success(`${processingId} imported successfully`);
  };

  const handleProcessingError = () => {
    setFile(undefined);
    setFileErrors(undefined);
    setProcessingId(undefined);
    onClose();
    ToastNotification.error(
      `View errors via Actions for more details. ${
        processingId ? `Import ID ${processingId}` : ""
      }`,
    );
  };

  return (
    <Modal.Root isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header>
        <Modal.Title>Import Inventory</Modal.Title>
      </Modal.Header>

      <Modal.Content>
        {processingId ? (
          <ProcessingLoader
            progress={progressPolling.data}
            onComplete={handleProcessingComplete}
            onError={handleProcessingError}
          />
        ) : (
          <div className="flex flex-1 flex-col">
            <p className="text-p1 font-bold">Select the inventory file:</p>

            {fileErrors && (
              <div className="grid w-full max-w-screen-sm gap-1 text-start">
                <h3 className="mb-2 text-h3">Errors:</h3>
                {fileErrors.map(fileError => (
                  <InfoBox
                    key={fileError.code}
                    variant="danger"
                    icon="triangle-exclamation"
                  >
                    {fileError.message}
                  </InfoBox>
                ))}
              </div>
            )}

            <form onSubmit={onSubmit}>
              <DropzoneField
                onChange={handleFileDropped}
                label=""
                acceptType={[".csv", ".xlsx"]}
                maxSize={5000 * 1024} // max 500MB
                isMultiple={false}
                value={file}
              >
                <div
                  key="content"
                  className="grid justify-items-center gap-6 py-8"
                >
                  <Icon
                    name="chart-network"
                    fontSize={32}
                    className="self-center"
                  />

                  <div className="grid gap-2">
                    <p className="text-p1">Drag and drop inventory file</p>
                    <p className="text-p1">.csv, .xsls max 500MB</p>
                  </div>

                  <span className="">or</span>

                  <Button type="button" size="small" variant="text">
                    Select from folder
                  </Button>
                </div>
              </DropzoneField>
            </form>
          </div>
        )}
      </Modal.Content>
    </Modal.Root>
  );
};

export default InventoryImport;
