import { SortingState } from "@tanstack/react-table";
import { useState } from "react";
import {
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { parseSorting } from "@ag/design-system/organisms";
import { usePagination } from "@ag/utils/hooks";

import { Filters, useSearchParamForm } from "~components/filters";
import Table from "~components/table";
import { BuyoutContractStatus } from "~features/buyout-agreements";
import {
  InventoryTableFilters,
  useInventoryCertificateGroupsQuery,
  useInventoryTable,
} from "~features/inventory";
import {
  CertificaGroupStatus,
  ContractType,
  InventoryFilters,
} from "~features/inventory/entities/inventory";
import { AuthorizedSidebar } from "~features/navigation";
import {
  CarbonResourceClass,
  useCarbonPermissions,
} from "~features/permission";
import { ResaleContractStatus } from "~features/resale-agreements";
import { transformedLabelValue } from "~helpers";
import ListLayout from "~layouts/list-layout";

const Inventory = () => {
  const [pagination, updatePagination, resetPagination] = usePagination();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [query, setQuery] = useQueryParams({
    importId: withDefault(StringParam, undefined),
    fieldId: withDefault(StringParam, undefined),
    status: withDefault(
      createEnumParam(Object.values(CertificaGroupStatus)),
      undefined,
    ),
    eligibleVerra: withDefault(StringParam, undefined),
    eligibleIso: withDefault(StringParam, undefined),
    vintageYear: withDefault(StringParam, undefined),
    countryCode: withDefault(StringParam, undefined),
    contractType: withDefault(
      createEnumParam(Object.values(ContractType)),
      undefined,
    ),
    contractId: withDefault(StringParam, undefined),
    contractStatus: withDefault(
      createEnumParam([
        ...Object.values(BuyoutContractStatus),
        ...Object.values(ResaleContractStatus),
      ]),
      undefined,
    ),
  });

  const {
    handleClearFiltersBar,
    handleClearFiltersDrawer,
    handleSubmitFilters,
  } = useSearchParamForm<InventoryFilters>(
    query,
    setQuery,
    resetPagination,
    () => setIsFiltersOpen(false),
  );

  const { data, isLoading } = useInventoryCertificateGroupsQuery({
    ...pagination,
    filters: {
      ...query,
      countryCode: query.countryCode ? [query.countryCode] : undefined,
      vintageYear: query.vintageYear ? [query.vintageYear] : undefined,
      status: query.status ? [query.status] : undefined,
    },
    sort: parseSorting(sorting),
  });

  const { data: carbonPermissions } = useCarbonPermissions();

  const canViewField = carbonPermissions?.read?.includes(
    CarbonResourceClass.CarbonField,
  );

  const renderFilterBarItem = (
    key: keyof InventoryFilters,
    value: InventoryFilters[keyof InventoryFilters],
  ) => {
    const elibilityStatus = {
      true: "Yes",
      false: "No",
    };
    const label = (
      {
        importId: "Import ID",
        fieldId: "Field ID",
        status: "Certificate Group Status",
        eligibleVerra: "Eligible Verra",
        eligibleIso: "Eligible Iso",
        vintageYear: "Vintage Year",
        countryCode: "Country",
        contractType: "Contract Type",
        contractId: "Contract ID",
        contractStatus: "Contract Status",
      } as Record<keyof InventoryFilters, string>
    )[key];

    if (key === "eligibleVerra" || key === "eligibleIso") {
      return `${label}: ${elibilityStatus[value as "true" | "false"]}`;
    }

    return `${label}: ${transformedLabelValue(value)}`;
  };

  const table = useInventoryTable(
    data?.items,
    {
      sorting,
      setSorting,
    },
    Boolean(canViewField),
  );

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Inventory</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
        <InventoryTableFilters
          isOpen={isFiltersOpen}
          onClose={() => setIsFiltersOpen(false)}
          query={query}
          onClear={handleClearFiltersDrawer}
          onSubmit={handleSubmitFilters}
        />
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header>
          <Filters.Bar
            values={query}
            renderItem={renderFilterBarItem}
            onToggleOpen={() => setIsFiltersOpen(value => !value)}
            onClear={handleClearFiltersBar}
          />
        </ListLayout.Header>

        <Table
          instance={table}
          meta={data?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={updatePagination}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default Inventory;
