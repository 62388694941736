import { Circle as GMCircle } from "@react-google-maps/api";
import { useState } from "react";

import {
  BoundariesDrawingMultiPolygon,
  BoundariesDrawingPolygon,
} from "@ag/carbon/components";
import { colorsObject } from "@ag/design-system/tokens";
import { FieldPolygon } from "@ag/map/components";
import { GeoJSONGeometry, GeometryType } from "@ag/map/types";
import { ToastNotification } from "@ag/utils/services";

import { useAllFieldBoundariesQuery } from "~features/field";
import { FieldBoundariesTypes } from "~features/field/entities/field-boundaries";

import type { BoundaryMapField as Field } from "../types/boundary-map-field";

type Props<T> = {
  field: Field<T>;
  activeBoundaryDisplayColor: Record<number, string>;
  isActiveBoundaryOpacityOn: boolean;
  isSelected?: boolean;
  isInEditMode?: boolean;
  isFarmerBoundaryVisible?: boolean;
  isMrvBoundaryVisible?: boolean;
  onClick?: (field: Field<T>, event: google.maps.MapMouseEvent) => void;
};

export const BoundaryManagementMapField = <T,>({
  field,
  activeBoundaryDisplayColor,
  isActiveBoundaryOpacityOn,
  isSelected,
  isInEditMode,
  isFarmerBoundaryVisible,
  isMrvBoundaryVisible,
  onClick,
}: Props<T>) => {
  const [isHovered, setIsHovered] = useState(false);

  const { data: allBoundariesForField } = useAllFieldBoundariesQuery(field.id, {
    enabled: isSelected,
  });

  const farmerReportedBoundary = allBoundariesForField?.find(
    boundary => boundary.type === FieldBoundariesTypes.FARMER,
  );
  const mrvDetectedBoundary = allBoundariesForField?.find(
    boundary => boundary.type === FieldBoundariesTypes.MRV,
  );

  const getPolygonOpacity = () => {
    if (
      !isActiveBoundaryOpacityOn ||
      ((isFarmerBoundaryVisible || isMrvBoundaryVisible) && isSelected)
    ) {
      return 0;
    }

    if (isSelected) {
      return 0.75;
    }

    if (isHovered && !isSelected) {
      return 0.5;
    }

    return 0.3;
  };

  return (
    <>
      {/* Active boundary */}
      {isInEditMode && isSelected ? (
        <>
          {field.geometry?.type === GeometryType.Polygon && (
            <BoundariesDrawingPolygon
              boundaries={field.geometry as GeoJSONGeometry}
              polygonOptions={{
                fillOpacity: 0,
                strokeWeight: 3,
                strokeColor: "#1400FF",
                zIndex: 10,
              }}
              onError={error => ToastNotification.warning(error)}
            />
          )}
          {field.geometry?.type === GeometryType.MultiPolygon && (
            <BoundariesDrawingMultiPolygon
              boundaries={field.geometry as GeoJSONGeometry}
              polygonOptions={{
                fillOpacity: 0,
                strokeWeight: 3,
                strokeColor: "#1400FF",
                zIndex: 10,
              }}
              onError={error => ToastNotification.warning(error)}
            />
          )}
        </>
      ) : (
        <>
          {field.geometry?.type === GeometryType.Point ? (
            <GMCircle
              center={{
                lat: field.geometry.coordinates[1],
                lng: field.geometry.coordinates[0],
              }}
              radius={12}
              options={{
                strokeColor: colorsObject.grey[900],
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: colorsObject.grey[300],
                fillOpacity: 1,
                zIndex: 10,
              }}
            />
          ) : (
            <FieldPolygon
              field={field}
              options={{
                fillOpacity: getPolygonOpacity(),
                ...{
                  strokeWeight: 3,
                  strokeColor:
                    field.harvestYear === 2024
                      ? activeBoundaryDisplayColor[400]
                      : colorsObject.grey[400],
                  fillColor:
                    field.harvestYear === 2024
                      ? activeBoundaryDisplayColor[400]
                      : colorsObject.grey[400],
                  zIndex: 10,
                },
              }}
              onMouseOver={() => setIsHovered(true)}
              onMouseOut={() => setIsHovered(false)}
              onClick={onClick}
            />
          )}
        </>
      )}

      {/* Farmer reported boundary */}
      {isFarmerBoundaryVisible &&
        isSelected &&
        farmerReportedBoundary?.boundaries?.type === GeometryType.Polygon && (
          <FieldPolygon
            field={{ geometry: farmerReportedBoundary.boundaries }}
            options={{
              fillOpacity: 0.6,
              ...{
                strokeWeight: 0,
                fillColor: colorsObject.data.green[500],
              },
            }}
          />
        )}

      {/* MRV detected boundary */}
      {isMrvBoundaryVisible &&
        isSelected &&
        mrvDetectedBoundary?.boundaries?.type === GeometryType.Polygon && (
          <FieldPolygon
            field={{ geometry: mrvDetectedBoundary.boundaries }}
            options={{
              fillOpacity: 0.5,
              ...{
                strokeWeight: 0,
                fillColor: colorsObject.data.pink[500],
              },
            }}
          />
        )}
    </>
  );
};
